import SM160 from "../assets/products/straight-pipes/M160.png";
import S160 from "../assets/products/straight-pipes/160.png";
import S250 from "../assets/products/straight-pipes/250.png";
import S315 from "../assets/products/straight-pipes/315.png";
import S450 from "../assets/products/straight-pipes/450.png";
import S630 from "../assets/products/straight-pipes/630.png";
import S800 from "../assets/products/straight-pipes/800.png";
import S1000 from "../assets/products/straight-pipes/1000.jpg";
import S1200 from "../assets/products/straight-pipes/1200.jpg";
import S1600 from "../assets/products/straight-pipes/1600.jpg";
import S2000 from "../assets/products/straight-pipes/2000.png";

import C315 from "../assets/products/pipe-cutting/315.jpg";
import C630 from "../assets/products/pipe-cutting/630.png";
import C800 from "../assets/products/pipe-cutting/800.jpg";
import C1200 from "../assets/products/pipe-cutting/1200.jpg";
import C1600 from "../assets/products/pipe-cutting/1600.jpg";

import SM630 from "../assets/products/fitting-fabrication/saddle-machines/630.jpg";
import SM1200 from "../assets/products/fitting-fabrication/saddle-machines/1200.jpg";

import CTY315 from "../assets/products/fitting-fabrication/cty/315.png";
import CTY450 from "../assets/products/fitting-fabrication/cty/450.jpg";
import CTY630 from "../assets/products/fitting-fabrication/cty/630.jpg";
import CTY800 from "../assets/products/fitting-fabrication/cty/800.png";
import CTY1000 from "../assets/products/fitting-fabrication/cty/1000.jpg";
import CTY1200 from "../assets/products/fitting-fabrication/cty/1200.jpg";
import CTY1600 from "../assets/products/fitting-fabrication/cty/1600.jpg";
import CTY2000 from "../assets/products/fitting-fabrication/cty/2000.jpg";

export const PRODUCT_SURVEY = {
  question: "What type of machine are you looking for?",
  options: [
    {
      name: "Straight Pipes",
      products: [
        {
          name: "WPFM160",
          image: SM160,
          description:
            "This manually operated butt-welding machine is designed for welding PE and PP pipes and fittings with diameters ranging from 40mm to 160mm OD. Its efficient design and construction make it an excellent low-cost option for both worksite and factory applications. The machine features high-quality aluminium castings, which reduce weight without compromising strength or performance. Additionally, an optional torque wrench is available for precise measurement of welding force.",
        },
        {
          name: "WPF160",
          image: S160,
          description:
            "This hydraulically operated butt-welding machine is designed for PE and PP pipes and fittings with diameters ranging from 50mm to 160mm OD, utilizing low-force welding methods. Its high-quality, pressure-cast aluminium castings ensure a lightweight design without compromising strength and performance, providing extra durability when welding out-of-round pipes. The machine is also certified CE approved, guaranteeing adherence to rigorous safety and quality standards.",
        },
        {
          name: "WPF250",
          image: S250,
          description:
            "The Weco Polymer 250 is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 50mm to 250mm OD. Its exceptional design and construction make it a premium choice for both worksite and factory welding applications. The machine features high-quality, pressure-cast aluminium castings, ensuring a lighter weight without compromising strength or performance, and providing added durability when welding out-of-round pipes. The Weco Polymer 250 offers flexibility, allowing welding between clamps 3 and 4, making it ideal for tasks such as tees, bends, and manifold work. It is also certified CE approved, ensuring compliance with rigorous safety and quality standards.",
        },
        {
          name: "WPF315",
          image: S315,
          description:
            "The Weco Polymer 315 is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 90mm to 315mm OD. This versatile machine allows for butt welding between clamps 3 and 4, making it ideal for tees, bends, and manifold work. It can also be seamlessly integrated with an optional data logger for enhanced performance monitoring. The Weco Polymer 315 is certified CE approved, ensuring it meets stringent safety and quality standards.",
        },
        {
          name: "WPF450",
          image: S450,
          description:
            "The Weco Polymer 450 is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 200mm to 450mm OD. With an available modification, it can also weld pipes as small as 160mm in SDR 9 and 11. This premium machine is ideal for both worksite and factory welding applications, boasting excellent design and construction. The Weco Polymer 450 is certified CE approved, ensuring it meets stringent safety and quality standards.",
        },
        {
          name: "WPF630",
          image: S630,
          description:
            'The Weco Polymer 630 is a hydraulically operated butt-welding machine designed for HDPE and PP pipes and fittings with diameters ranging from 315mm to 630mm (12" to 24"). It supports welding in accordance with ISO/DVS/POP003 standards for both SLP and DLP low-force methods, making it a versatile and reliable choice for both worksite and factory applications. For enhanced functionality, the Weco Polymer 630 can be seamlessly integrated with an optional data logger and overhead electric crane. The machine is certified CE approved, ensuring compliance with rigorous safety and quality standards.',
        },
        {
          name: "WPF800",
          image: S800,
          description:
            'The Weco Polymer 800 LF is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 450mm (optionally 400mm) to 800mm OD, and from 18" to 30" IPS. This premium machine offers excellent design and construction, making it ideal for welding both on the worksite and in the factory. For enhanced functionality, it can be integrated with an optional data logger and overhead electric crane. The Weco Polymer 800 LF is certified CE approved, ensuring it meets stringent safety and quality standards.',
        },
        {
          name: "WPF1000",
          image: S1000,
          description:
            'The Weco Polymer 1000 is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 500mm to 1000mm, or 36" DIPS to 20" OD. Its excellent design and construction make it a premium choice for welding both on the worksite and in the factory. The machine allows welding between clamps 3 and 4, making it ideal for tees, bends, and manifold work. It can also be easily integrated with optional features such as a data logger and overhead electric crane. The Weco Polymer 1000 is certified CE approved, ensuring adherence to rigorous safety and quality standards.',
        },
        {
          name: "WPF1200",
          image: S1200,
          description:
            'The Weco Polymer 1200/48 is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 630mm to 1200mm OD, or alternatively 24" to 48". The machine allows welding between clamps 3 and 4, making it suitable for tees, bends, and manifold work. It can be integrated with optional features such as a data logger and an overhead electric crane. The Weco Polymer 1200/48 is certified CE approved, ensuring it meets stringent safety and quality standards.',
        },
        {
          name: "WPF1600",
          image: S1600,
          description:
            "The Weco Polymer 1600 is a CNC-controlled, hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 900mm to 1600mm OD. This advanced machine represents over 60 years of expertise in the PE pipe industry, offering premium equipment for both worksite and factory applications. The Weco Polymer 1600 combines a lightweight design with exceptional strength and performance. It is operated via a portable wired console with flexible touch screen functionality. Key features include high-quality hinged steel main clamps with hydraulic open/close and locking mechanisms, ensuring secure and reliable operation.Additional features include a Remote Monitoring System for online assistance, PLC control of temperature, time, and pressure, and onboard data logging capabilities. The machine also offers the flexibility to weld between clamps 2 & 3 or 3 & 4, making it an exceptionally versatile tool for complex welding tasks. The Weco Polymer 1600 is certified CE approved, meeting rigorous safety and quality standards.",
        },
        {
          name: "WPF2000",
          image: S2000,
          description:
            "The Weco Polymer 2000 Hydra LF is a hydraulically operated butt-welding machine designed for PE and PP pipes and fittings with diameters ranging from 1400mm to 2000mm OD. It features high-quality hinged steel main clamps with hydraulic open/close and locking functions, ensuring secure and reliable performance. The machine includes a Remote Monitoring System for online assistance, PLC control for temperature, time, and pressure, and onboard data logging capabilities. The Weco Polymer 2000 Hydra LF is certified CE approved, guaranteeing adherence to stringent safety and quality standards.",
        },
      ],
    },
    {
      name: "Saddle Machines",
      products: [
        {
          name: "WPFSS630",
          image:
            "https://t4.ftcdn.net/jpg/04/99/10/95/360_F_499109533_eSJkDKEilqy5EDyE8uJUBool0Ex5A34R.jpg",
          description:
            'Hydraulically operated saddle fusion machine for the production of 90-degree reducing tees from standard PE pipe sections. Capacity 315 to 630mm OD (12" to 24") main pipe / 63 to 315mm (2" to 12") branch pipe, where branch pipe must not exceed 50% of the main diameter. Machine is constructed to allow operation by one person, incorporating main pipe clamp, branch pipe clamps, hydraulically removed heating plate with curved elements and integrated hole saw for final removal of pipe coupon.',
        },
        {
          name: "WPFSS1200",
          image:
            "https://t4.ftcdn.net/jpg/04/99/10/95/360_F_499109533_eSJkDKEilqy5EDyE8uJUBool0Ex5A34R.jpg",
          description:
            'Hydraulically operated saddle fusion machine for the production of 90-degree reducing tees from standard PE pipe sections. Capacity 315 to 630mm OD (12" to 24") main pipe / 63 to 315mm (2" to 12") branch pipe, where branch pipe must not exceed 50% of the main diameter. Machine is constructed to allow operation by one person, incorporating main pipe clamp, branch pipe clamps, hydraulically removed heating plate with curved elements and integrated hole saw for final removal of pipe coupon.',
        },
      ],
    },
    {
      name: "Crosses, Tees & Ys",
      products: [
        {
          name: "WPFW315",
          image: CTY315,
          description:
            "The Weco Polymer Fusion Workshop WPFW315 is a user-friendly, hydraulically operated machine tailored for butt-welding pipes and fittings in PE, PP, and other thermoplastic materials. Equipped with pivoting clamps, it supports the fabrication of segmented bends across various radii. Optional clamps extend its functionality, allowing the production of bends from 90 to 315mm OD, 90° equal tees and crosses, as well as 45° and 60° branch tees within the 90mm to 315mm range. Provisioned for data logger integration and certified with genuine CE approval, the WPFW315 offers a versatile and reliable solution for workshop applications.",
        },
        {
          name: "WPFW450",
          image: CTY450,
          description:
            "The Weco Polymer Fusion Workshop WPFW450 is a hydraulically operated workshop machine specifically engineered for producing butt-welded fittings from PE and PP pipes. With CNC control, it ensures precise welding parameters and features a built-in data logger for tracking weld history. Standard clamps support the creation of various bends, while optional hydraulically locking clamps facilitate smooth, reliable operation. Liner clamps extend the machine's functionality, enabling the production of bends from 160mm to 450mm OD, 90° equal tees and crosses, as well as 45° and 60° branch tees in the 160mm to 450mm range. Equipped with a CNC touch-screen interface pre-programmed with various standard settings, the WPFW450 provides streamlined, user-friendly welding control. This machine comes with genuine CE certification.",
        },
        {
          name: "WPFW630",
          image: CTY630,
          description:
            "The Weco Polymer Fusion Workshop WPFW630 is a hydraulically operated workshop machine specifically engineered for producing butt-welded fittings from PE and PP pipes. With CNC control, it ensures precise welding parameters and features a built-in data logger for tracking weld history. Standard clamps support the creation of various bends, while optional hydraulically locking clamps facilitate smooth, reliable operation. Liner clamps extend the machine's functionality, enabling the production of bends from 315mm to 630mm OD, 90° equal tees and crosses, as well as 45° and 60° branch tees in the 315mm to 630mm range. Equipped with a CNC touch-screen interface pre-programmed with various standard settings, the WPFW630 provides streamlined, user-friendly welding control. This machine comes with genuine CE certification.",
        },
        {
          name: "WPFW800",
          image: CTY800,
          description:
            "The Weco Polymer Fusion Workshop WPFW450 is a hydraulically operated workshop machine specifically engineered for producing butt-welded fittings from PE and PP pipes. With CNC control, it ensures precise welding parameters and features a built-in data logger for tracking weld history. Standard clamps support the creation of various bends, while optional hydraulically locking clamps facilitate smooth, reliable operation. Liner clamps extend the machine's functionality, enabling the production of bends from 450mm to 800mm OD, 90° equal tees and crosses, as well as 45° and 60° branch tees in the 450mm to 800mm range. Equipped with a CNC touch-screen interface pre-programmed with various standard settings, the WPFW800 provides streamlined, user-friendly welding control. This machine comes with genuine CE certification.",
        },
        {
          name: "WPFW1000",
          image: CTY1000,
          description:
            "The Weco Polymer Fusion Workshop WPFW1000 is a hydraulically operated workshop machine designed for precise butt-welding of pipes and fittings in PE, PP, and other thermoplastic materials. Equipped with CNC control, it maintains exact welding parameters and includes an integrated data logger to track weld history for quality assurance. Standard clamps allow for versatile bending, while optional hydraulically locking clamps enable smooth, dependable operation. Liner clamps expand capabilities, allowing production of bends from 500mm to 1000mm OD, 90° equal tees and crosses, and 45° and 60° branch tees within the 500mm to 1000mm range. The CNC touch-screen interface, pre-programmed with standard welding settings, offers a user-friendly experience and enhances operational efficiency. Certified with genuine CE approval, the WPFW1000 delivers reliable and accurate welding control for demanding workshop applications.",
        },
        {
          name: "WPFW1200",
          image: CTY1200,
          description:
            "The Weco Polymer Fusion Workshop WPFW1200 is a hydraulically operated workshop machine expertly designed for butt-welding HDPE and PP pipes and fittings. It is equipped with CNC control to maintain precise welding parameters and includes an integrated data logger to track weld history, ensuring quality assurance. Standard clamps offer versatile bending options, while optional hydraulically locking clamps enhance smooth, reliable operation. Liner clamps extend the machine’s capabilities, enabling the production of bends from 630mm to 1200mm OD, 90° equal tees and crosses, and 45° and 90° branch tees across the same diameter range. Its CNC touch-screen interface, pre-programmed with standard welding settings, provides a user-friendly experience and boosts operational efficiency. Certified with genuine CE approval, the WPFW1200 is engineered to deliver dependable and precise welding control for demanding workshop applications. Notably, this machine produces all fitting types across all diameters within its range, offering unmatched versatility. Welding is executed with variable force for optimal control across diverse applications.",
        },
        {
          name: "WPFW1600",
          image: CTY1600,
          description:
            "The Weco Polymer Fusion Workshop WPFW1600 is a hydraulically operated workshop machine designed specifically for butt-welding HDPE and PP pipes and fittings. It features CNC control to maintain precise welding parameters and is equipped with an integrated data logger to monitor weld history, ensuring quality assurance throughout the welding process.This machine includes standard clamps for versatile bending options, while optional hydraulically locking clamps provide enhanced smooth and reliable operation. Liner clamps expand the machine's capabilities, allowing the production of bends from 900mm to 1600mm OD, with an extendable minimum diameter of 710mm, subject to conditions. It can also produce 90° equal tees and crosses, as well as 45° and 90° branch tees across the same diameter range.The CNC touch-screen interface is pre-programmed with standard welding settings, offering a user-friendly experience that enhances operational efficiency. Certified with genuine CE approval, the WPFW1600 is engineered for dependable and precise welding control in demanding workshop applications. Importantly, this machine can produce all fitting types across its entire diameter range, providing unmatched versatility. Welding is performed with variable force for optimal control across diverse applications.",
        },
        {
          name: "WPFW2000",
          image: CTY2000,
          description:
            "The Weco Polymer Fusion Workshop WPFW2000 is a hydraulically operated workshop machine designed specifically for butt-welding HDPE and PP pipes and fittings. It features CNC control to maintain precise welding parameters and is equipped with an integrated data logger to monitor weld history, ensuring quality assurance throughout the welding process.This machine includes standard clamps for versatile bending options, while optional hydraulically locking clamps provide enhanced smooth and reliable operation. Liner clamps expand the machine's capabilities, allowing the production of bends from 1400mm to 2000mm OD, with an extendable minimum diameter of 1200mm, subject to conditions. It can also produce 90° equal tees and crosses, as well as 45° and 90° branch tees across the same diameter range.The CNC touch-screen interface is pre-programmed with standard welding settings, offering a user-friendly experience that enhances operational efficiency. Certified with genuine CE approval, the WPFW2000 is engineered for dependable and precise welding control in demanding workshop applications. Importantly, this machine can produce all fitting types across its entire diameter range, providing unmatched versatility. Welding is performed with variable force for optimal control across diverse applications.",
        },
      ],
    },
    {
      name: "Pipe Saw",
      products: [
        {
          name: "WPFS315",
          image: C315,
          description:
            'The Weco Polymer WPFS315 is a hydraulically operated pipe saw, expertly designed for preparing pipe segments in PE, PP, and other thermoplastic materials. Ideal for producing bends, tees, and other fabrications, it has a capacity range from 90 to 630mm OD (3" to 24" IPS). The cutting angle spans from 0 to +45 degrees and extended 67.5 degree with a hydraulically controlled lowering rate and strap clamps that securely hold pipe segments in place. The saw blade, made in Sweden, ensures exceptional precision and durability. The WPFS315 is CE approved, meeting strict European safety and quality standards.',
        },
        {
          name: "WPFS630",
          image: C630,
          description:
            'The Weco Polymer WPFS630 is a hydraulically operated pipe saw designed specifically for preparing pipe segments in PE, PP, and other thermoplastic materials, making it ideal for producing bends, tees, and other fabrications. It has a capacity of 90 to 630mm OD (3" to 24" IPS) with a cutting angle range from 0 to +45 and extended 67.5 degrees. The saw\'s lowering rate and strap clamps, which securely hold pipe segments in place, are hydraulically controlled for precise operation. Equipped with a high-quality saw blade made in Sweden. The WPFS630 is backed by genuine CE certification, ensuring compliance with European safety and quality standards.',
        },
        {
          name: "WPFS800",
          image: C800,
          description:
            'The Weco Polymer WPFS800 is a hydraulically operated pipe saw crafted specifically for the preparation of pipe segments in PE, PP, and other thermoplastic materials. Ideal for fabricating bends, tees, and other complex components, it accommodates pipe diameters from 90 to 800mm OD (3" to 32" IPS). With a cutting angle range from 0 to +45 and extended 67.5 degrees. The WPFS800 offers precise control via hydraulically regulated lowering and strap clamps that securely hold pipe segments in place. The saw features a high-quality blade made in Sweden and is backed by genuine CE certification, meeting rigorous European safety and quality standards.',
        },
        {
          name: "WPFS1200",
          image: C1200,
          description:
            'The Weco Polymer WPFS1200 is a hydraulically operated pipe saw, meticulously engineered for preparing pipe segments in PE, PP, and other thermoplastic materials. Ideal for producing bends, tees, and intricate fabrications, it handles pipe diameters from 400 to 1200mm OD (15" to 48" IPS). The cutting angle ranges from 0 to +45 degrees and can extend to 67.5 degrees with additional accessories. Equipped with CNC-controlled hydraulic lowering and hydraulic movable and stationary clamps, the WPFS1200 ensures secure and precise operations. It features a touch-screen data input for enhanced user control, and the high-quality saw blade is made in Sweden, with genuine CE approval confirming compliance with European safety and quality standards.',
        },
        {
          name: "WPFS1600",
          image: C1600,
          description:
            'The Weco Polymer WPFS1600 is a hydraulically operated pipe saw, specifically engineered for the preparation of pipe segments in PE, PP, and other thermoplastic materials. Designed for producing bends, tees, and other complex fabrications, it accommodates pipe diameters ranging from 800 to 1600mm OD (30" to 63" IPS). The cutting angle ranges from 0 to +45 degrees and can extend to 67.5 degrees with additional accessories. Equipped with CNC-controlled hydraulic lowering and hydraulic movable and stationary clamps, the WPFS1600 ensures secure and precise operations. It features a touch-screen data input for enhanced user control, and the high-quality saw blade is made in Sweden, with genuine CE approval confirming compliance with European safety and quality standards.',
        },
      ],
    },
  ],
};
