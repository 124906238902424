import { InputField } from "./Common/InputField";
import { Button } from "./Common/Button";
import React from "react";
import emailjs from "@emailjs/browser";
import polyWeldLogo from "../assets/logos/poly-weld.jpg";

export const Contact = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [stateMessage, setStateMessage] = React.useState(null);

  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [mobileNumber, setMobileNumber] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [content, setContent] = React.useState(null);

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .send(
        "service_qid8diz",
        "template_s46xxkm",
        { firstName, lastName, email, mobileNumber, content },
        "D5788PLd50ITsixsm"
      )
      .then(
        (result) => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };

  return (
    <div className="">
      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full -mt-16 px-5 md:px-0">
        <div className="grid md:grid-cols-3 gap-3 md:gap-8">
          <div className="h-32 bg-background rounded-lg w-full flex items-center gap-6 p-6">
            <div className="rounded-full h-14 w-14 flex items-center justify-center bg-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-background"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            <div className="flex flex-col">
              <div className="font-semibold uppercase tracking-wider text-white">
                Head Office (Australia)
              </div>
              <div className="font-bold text-xl md:text-2xl uppercase tracking-wider text-white">
                +61 03 5766 1260
              </div>
            </div>
          </div>

          <div className="h-32 bg-primary rounded-lg w-full flex items-center gap-6 p-6">
            <div className="rounded-full h-14 w-14 flex items-center justify-center bg-background">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-primary"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            <div className="flex flex-col">
              <div className="font-semibold uppercase tracking-wider text-background">
                International Hotline
              </div>
              <div className="font-bold text-xl md:text-2xl uppercase tracking-wider text-background">
                +61 483 376 427
              </div>
            </div>
          </div>

          <div className="h-32 bg-background rounded-lg w-full flex items-center gap-6 p-6">
            <div className="rounded-full h-14 w-14 flex items-center justify-center bg-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-background"
              >
                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
              </svg>
            </div>

            <div className="flex flex-col">
              <div className="font-semibold uppercase tracking-wider text-white">
                Email Us
              </div>
              <div className="font-bold text-xl md:text-2xl uppercase tracking-wider text-white">
                sales@wpfusion.com.au
              </div>
            </div>
          </div>
        </div>

        <div className="py-6 md:py-14 gap-12">
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <div className="flex flex-col flex-1">
                <div className="tracking-widest text-primary uppercase font-bold text-lg">
                  Authorised Domestic Sales and Service (Australia)
                </div>

                <div className="text-background font-bold text-6xl mb-5">
                  Poly Weld Industries Pty Ltd
                </div>
              </div>
              <img
                src={polyWeldLogo}
                alt="Poly weld logo"
                className="h-auto max-w-72 w-full"
              />
            </div>

            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 text-primary"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <div className="font-medium">+61 488 775 023</div>
              </div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 text-primary"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                  <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>

                <div className="font-medium">
                  <a href="mailto:info@polyweldindustries.com.au">
                    info@polyweldindustries.com.au
                  </a>
                </div>
              </div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5 text-primary"
                >
                  <path
                    fill-rule="evenodd"
                    d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <div className="font-medium">
                  1/17 Rutherford Court, Maddingley VIC 3340 Australia
                </div>
              </div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 text-primary"
                >
                  <path d="M21.721 12.752a9.711 9.711 0 0 0-.945-5.003 12.754 12.754 0 0 1-4.339 2.708 18.991 18.991 0 0 1-.214 4.772 17.165 17.165 0 0 0 5.498-2.477ZM14.634 15.55a17.324 17.324 0 0 0 .332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 0 0 .332 4.647 17.385 17.385 0 0 0 5.268 0ZM9.772 17.119a18.963 18.963 0 0 0 4.456 0A17.182 17.182 0 0 1 12 21.724a17.18 17.18 0 0 1-2.228-4.605ZM7.777 15.23a18.87 18.87 0 0 1-.214-4.774 12.753 12.753 0 0 1-4.34-2.708 9.711 9.711 0 0 0-.944 5.004 17.165 17.165 0 0 0 5.498 2.477ZM21.356 14.752a9.765 9.765 0 0 1-7.478 6.817 18.64 18.64 0 0 0 1.988-4.718 18.627 18.627 0 0 0 5.49-2.098ZM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 0 0 1.988 4.718 9.765 9.765 0 0 1-7.478-6.816ZM13.878 2.43a9.755 9.755 0 0 1 6.116 3.986 11.267 11.267 0 0 1-3.746 2.504 18.63 18.63 0 0 0-2.37-6.49ZM12 2.276a17.152 17.152 0 0 1 2.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0 1 12 2.276ZM10.122 2.43a18.629 18.629 0 0 0-2.37 6.49 11.266 11.266 0 0 1-3.746-2.504 9.754 9.754 0 0 1 6.116-3.985Z" />
                </svg>

                <div className="font-medium">
                  <a href="https://polyweldindustries.com.au">
                    www.polyweldindustries.com.au
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-10 md:pb-24 grid md:grid-cols-2 gap-12">
          <div className="flex flex-col">
            <div className="tracking-widest text-primary uppercase font-bold text-lg">
              Get In Touch
            </div>

            <div className="text-background font-bold text-6xl mb-5">
              Send Us a <span className="text-primary">Message</span>
            </div>

            <form onSubmit={sendEmail}>
              <div className="space-y-3">
                <div className="grid grid-cols-2 gap-4">
                  <InputField
                    label="First Name"
                    placeholder="John"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <InputField
                    label="Last Name"
                    placeholder="Doe"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <InputField
                    label="Your Email"
                    placeholder="john@doe.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <InputField
                    label="Your Number"
                    placeholder="+6123456789"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>

                <InputField
                  label="Your Message"
                  type="textarea"
                  rows={8}
                  placeholder="When messaging us, please provide as much detail as possible to help us satisfy your request..."
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />

                <Button
                  className="!font-semibold"
                  buttonStyle="primary"
                  label="Send Message"
                  size="large"
                  rightIcon
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  type="submit"
                />
              </div>
            </form>
          </div>

          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3150.8137979308335!2d144.97418951245797!3d-37.84124477185179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6693aa464baf9%3A0xbd7c4b011b939aeb!2s470%20St%20Kilda%20Rd%2C%20Melbourne%20VIC%203004!5e0!3m2!1sen!2sau!4v1713328977259!5m2!1sen!2sau"
              width="100%"
              height="100%"
              title="Address location"
              allowfullscreen={false}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="border-0 rounded-lg"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
